import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { SendMessageModelCsKind } from "src/app/dto/SendMessageModelCsKind";
import { SendMessageGeneric } from "src/app/dto/SendMessageGeneric";
import { ActionResponse } from "src/app/dto/ActionResponse";
import { Commonservice } from '../servicecenter/commonservice.component';

@Component({
  selector: 'send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.css'],
  providers: [MessageService]
})
export class SendMessageComponent implements OnInit {
  message: string;
  isReadOnly = false; 
  @ViewChild("sendMessageForm") sendMessageForm: NgForm;
  showMessage = false;
  @Output() hideSendMessageModel = new EventEmitter<SendMessageGeneric>();
  @Output() OnSubmit = new EventEmitter<ActionResponse>();


  constructor(private commonservice: Commonservice, private messageService: MessageService) {

  }

  resetForm() {
    const self = this;
    self.showMessage = false;
    self.isReadOnly = false;
    this.sendMessageForm.resetForm(true);
  }

  ngOnInit() {
    this.showMessage = false;
  }

  onSubmit(form: NgForm) {
    const self = this;
    self.showMessage = false;
    if (form.valid) {
      let smi = new SendMessageModelCsKind();
      self.commonservice.GetSendMessage().subscribe(message => {
        smi.ToUser = message.ToUser;
        smi.FromUser = message.FromUser;
        smi.Message = self.message;
      });
      self.commonservice.sendMessage(smi).subscribe(data => {
        let actionState = new ActionResponse();
        if (data.InsertWorked) {          
          self.showMessage = true;
          actionState.ActionState = true;
        }
        else {
          actionState.ActionState = false;
        }
        actionState.Message = data.Message;
        this.OnSubmit.next(actionState);
        self.isReadOnly = true;
      });
    }
  }

  hideSendMessageWindow() {
    let smg = {} as SendMessageGeneric;
    smg.FromUser = "";
    smg.ToUser = "";
    smg.ShowSendMessage = false;
    this.hideSendMessageModel.next(smg);
  }

}

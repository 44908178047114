import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoginUserDto } from "src/app/dto/LoginUserDto";
import { CommonNewservice } from 'src/app/common/reusable/servicecenter/commonNewService';
import * as localForage from "localforage";
import { MessageService } from 'primeng/api';
import { LoginResponseModel } from 'src/app/dto/LoginResponseModel';
import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { UserInformation } from 'src/app/db.service';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { APP_CONSTANTS } from 'src/app/common/reusable/contants/app.constants';

localForage.setDriver([
  localForage.INDEXEDDB,
  localForage.LOCALSTORAGE,
  localForage.WEBSQL,
]);

// Using config()
localForage.config({
  driver: [
    localForage.INDEXEDDB,
    localForage.LOCALSTORAGE,
    localForage.WEBSQL,
  ],
  name: 'myApp',     // These fields
  version: 1.0,      // are totally optional
});

@Component({
  selector: 'loginchome',
  templateUrl: './loginchome.component.html',
  styleUrls: ['./loginchome.component.css'],
  providers: [MessageService],
  animations: [
    trigger('flip', [
      state('front', style({
        transform: 'rotateY(0deg)'
      })),
      state('back', style({
        transform: 'rotateY(180deg)'
      })),
      transition('front => back', [
        animate('1s 0s ease-out',
          keyframes([
            style({
              transform: 'perspective(400px) rotateY(0deg)',
              offset: 0
            }),
            style({
              transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotateY(80deg)',
              offset: 0.4
            }),
            style({
              transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotateY(100deg)',
              offset: 0.5
            }),
            style({
              transform: 'perspective(400px) scale3d(0.95, 0.95, 0.95) rotateY(180deg)',
              offset: 0.8
            }),
            style({
              transform: 'perspective(400px) rotateY(180deg)',
              offset: 1
            })
          ]))
      ]),
      transition('back => front', [
        animate('1s 0s ease-in',
          keyframes([
            style({
              transform: 'perspective(400px) rotateY(180deg)',
              offset: 0
            }),
            style({
              transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotateY(100deg)',
              offset: 0.4
            }),
            style({
              transform: 'perspective(400px) scale3d(1.5, 1.5, 1.5) rotateY(80deg)',
              offset: 0.5
            }),
            style({
              transform: 'perspective(400px) scale3d(0.95, 0.95, 0.95) rotateY(0deg)',
              offset: 0.8
            }),
            style({
              transform: 'perspective(400px) rotateY(0deg)',
              offset: 1
            })
          ]))
      ])
    ])
  ]
})
export class LoginchomeComponent implements OnInit {
  router: Router;
  username: string;
  password: string;
  errorThatMessage: string;
  user: LoginUserDto;
  theKey = "UserInformation";
  email: string;
  flipState = 'front';
  currentClass: string;

  showLoginPage: boolean = true;
  currentDeviceState: string = "";
  constructor(private commonService: CommonNewservice, _router: Router, private messageService: MessageService, private signalStateService: SignalStateService) {
    this.router = _router;
  }

  ngOnInit() {
    const self = this;
    // sessionStorage.clear();
    self.initiateUserInfo();
  }

  initiateUserInfo() {
    const self = this;
    self.password = '';
    self.username = '';
    self.errorThatMessage = '';
    self.user = {} as LoginUserDto;
    self.user.Username = '';
    self.user.Password = '';
    this.currentDeviceState = this.signalStateService.getValue(APP_CONSTANTS.ResolutionData)();
    this.setScreenClass(this.currentDeviceState);
    this.setDimensionBasedOnBrowser();
  }

  setScreenClass(screenType: string) {
    switch (screenType) {
      case "desktop-landscape":
        this.currentClass = 'image';
        break;
      case "mobile-portrait":
        this.currentClass = 'image-mobile';
        break;
      case "tablet-portrait":
        this.currentClass = 'image-mobile';
        break;
      case "tablet-landscape":
        this.currentClass = 'tablet-landscape';
        break;
      case "desktop-portrait":
        this.currentClass = 'desktop-portrait';
        break;
      default:
        this.currentClass = 'image';
        break;
    }
  }

  setDimensionBasedOnBrowser() {
    let browserType = 0;

    if (navigator.userAgent.includes('OPR')) {
      browserType = 3;
    }
    if (navigator.userAgent.includes('Firefox')) {
      browserType = 2;
    }

    switch (browserType) {
      case 3:
        this.currentClass = 'image-opera';
        break;
      case 2:
        this.currentClass = 'image-firefox';
        break;
    }
  }

  loginHome() {
    const self = this;
    // sessionStorage.clear();
    self.user.Username = self.username;
    self.user.Password = self.password;
    self.commonService.login(self.user).subscribe(
      data => {
        self.runNow(data);
      },
      error => {
        self.catchError(error);
      }
    );

  }

  catchError(response: any) {
  }

  runNow(response: LoginResponseModel) {
    const self = this;
    if (response.State === 1) {
      sessionStorage.clear();
      self.signalStateService.clearAll();
      setTimeout(() => {
        self.signalStateService.setValue(APP_CONSTANTS.token, JSON.stringify(response.AccessToken));
        self.signalStateService.setValue(APP_CONSTANTS.refresherToken, JSON.stringify(response.RefreshToken.Token));
        self.signalStateService.setValue(APP_CONSTANTS.userLoggedIn, JSON.stringify(response.UserName));
        self.signalStateService.setValue(APP_CONSTANTS.name, JSON.stringify(response.Name));
        window.name = JSON.stringify(response.AccessToken);
        let ui = {} as UserInformation;
        ui.Name = response.Name;
        ui.UserName = response.UserName;
        ui.AccessToken = response.AccessToken;
        self.signalStateService.setValue(APP_CONSTANTS.loginResponse, JSON.stringify(response));
        self.signalStateService.setValue(APP_CONSTANTS.userLoggedIn, JSON.stringify(ui));
        if (response.IsEnabled) {
          self.router.navigateByUrl('/home');
        } else {
          self.router.navigateByUrl('/intermediate');
        }

      }, 120);
    } else {
      this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: response.Msg });
    }
  }

  cancel() {
    const self = this;
    this.showLoginPage = !this.showLoginPage;
  }

  backToHome() {
    this.showLoginPage = !this.showLoginPage;
  }

  SendToRegister() {
    const self = this;
    self.router.navigateByUrl('/register');
  }

  submitReset(event: any) {
    this.commonService.sendResetLink(this.email).subscribe(
      data => {
        if (data.ActivityWorked) {
          this.messageService.add({ severity: 'success', summary: 'Service Message', detail: "Mail is send to " + this.email });
        }
        else {
          this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: "mail could not be send due to technical issue" });
        }
      },
      error => {
        this.messageService.add({ severity: 'warn', summary: 'Service Message', detail: "mail could not be send due to technical issue" });
      }
    );
  }
}

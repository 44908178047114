<publicpage-register>
  <div class="payment-options">
    <!-- pricing.component.html -->
    <div class="pricing-container">
      <!-- Header Section -->
      <div class="header-section">
        <membership-benefits></membership-benefits>
      </div>



      <!-- Pricing Plans Grid -->

    </div>
    <subscription-plans></subscription-plans>
    <!-- <comparison-chart></comparison-chart> -->
  </div>
</publicpage-register>
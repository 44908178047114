<publicpage>

  <div [ngClass]="currentClass">
    <slideshow></slideshow>
  </div>
  <div class="content-section grid kill-the-margin">
    <div class="col-12 sm:col-6 md:col-4 lg:col-3" style="padding: 0px;">
      <loginchome></loginchome>
    </div>
    <div class="col-12 sm:col-6 md:col-8 lg:col-9" style="padding: 0px;">
      <loginhomeoffers></loginhomeoffers>
    </div>
  </div>

</publicpage>
import { Component } from '@angular/core';

@Component({
  selector: 'membership-benefits',
  templateUrl: './membership-benefits.component.html',
  styleUrl: './membership-benefits.component.css'
})
export class MembershipBenefitsComponent {

}

<form #sendMessageForm="ngForm" (ngSubmit)="onSubmit(sendMessageForm)">
  <div class="flex flex-column">
    <div class="col">
      <textarea [rows]="5" [cols]="70" pInputTextarea id="usermessage" name="usermessage" #usermessage="ngModel"
        required minlength="1" maxlength="250" [(ngModel)]="message" [readonly]="isReadOnly"
        [class.field-error]="sendMessageForm.submitted && usermessage.invalid"></textarea>
    </div>
    <div class="col" [hidden]="usermessage.valid || !sendMessageForm.submitted">
      <p-messages severity="error">
        <ng-template pTemplate>
          Please enter the message to be send
        </ng-template>
      </p-messages>
    </div>
    <div class="col row">
      <ng-container *ngIf="!showMessage">
        <button icon="pi pi-times-circle" type="submit" class="button button-send">Send</button>
        <button icon="pi pi-times-circle" (click)="hideSendMessageWindow()" class="button button-cancel">Cancel</button>
        <spacer></spacer>
      </ng-container>
      <div *ngIf="showMessage">
        <button icon="pi pi-times-circle" (click)="hideSendMessageWindow()" class="button button-cancel">Ok</button>
      </div>
          {{ message }}
    </div>
  </div>
</form>

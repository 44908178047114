<div class="intermediate-container">
    <div class="card">
      <div class="header">
        <h2>Account Temporarily Disabled</h2>
      </div>
      
      <div class="content">
        <p class="message">
          Your account has been temporarily disabled due to activities that do not align 
          with our platform's guidelines and community standards.
        </p>
        
        <div class="actions">
          <button 
            pButton 
            label="Contact Administrator" 
            class="p-button-primary mr-2" 
            (click)="toggleContactForm()"
          ></button>
          
          <button 
            pButton 
            label="Logout" 
            class="p-button-danger" 
            (click)="onLogout()"
          ></button>
        </div>
        
        <!-- Conditional Contact Form -->
        <div *ngIf="showContactForm" class="contact-form mt-3">
          <p-editor 
            [(ngModel)]="text" 
            [style]="{ height: '320px' }"
            placeholder="Write your message to the administrator..."
          ></p-editor>
          
          <div class="send-actions mt-2">
            <button 
              pButton 
              label="Send Message" 
              class="p-button-success" 
              (click)="sendMessageToAdmin()"
            ></button>
            <button 
              pButton 
              label="Cancel" 
              class="p-button-secondary ml-2" 
              (click)="toggleContactForm()"
            ></button>
          </div>
        </div>
      </div>
    </div>
    
    <!-- PrimeNG Toast for notifications -->
    <p-toast/>
  </div>
<super-floating-loader [isLoading]="showLoader"></super-floating-loader>
<div class="hor-row">
  <div class="row" style="width: 100%">
    <div class="r-p-0 col-5">
      <img [src]="tc?.MainImage" class="image-display img-thumbnail" />
    </div>
    <div class="col-7">
      <div class="gallery-grid">
        <figure *ngFor="let item of tc?.ThumbNailImages" class="gallery-frame">
          <img [src]="item.Image" class="image-display-thumbnail img-thumbnail gallery-img"
            (click)="callLargeImage(item.Id)" />
        </figure>
      </div>
      <div class="parent-container">
        <div class="icons-container">
          <div class="icon">
            <div style="font-size: 1.1rem" pTooltip="Express Interest" tooltipPosition="left"
              (click)="expressInterestPop($event)" tooltipStyleClass="custom-tooltip">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                stroke="#e63946" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-hand-heart zoom-icon">
                <path d="M11 14h2a2 2 0 1 0 0-4h-3c-.6 0-1.1.2-1.4.6L3 16" />
                <path d="m7 20 1.6-1.4c.3-.4.8-.6 1.4-.6h4c1.1 0 2.1-.4 2.8-1.2l4.6-4.4a2 2 0 0 0-2.75-2.91l-4.2 3.9" />
                <path d="m2 15 6 6" />
                <path
                  d="M19.5 8.5c.7-.7 1.5-1.6 1.5-2.7A2.73 2.73 0 0 0 16 4a2.78 2.78 0 0 0-5 1.8c0 1.2.8 2 1.5 2.8L16 12Z" />
              </svg>
            </div>
          </div>
          <div class="icon">
            <div pTooltip="Bookmark" tooltipPosition="top" (click)="bookmarkprofile()"
              tooltipStyleClass="custom-tooltip">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                stroke="#70e3ff" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                class="lucide lucide-bookmark-plus zoom-icon">
                <path d="m19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16z" />
                <line x1="12" x2="12" y1="7" y2="13" />
                <line x1="15" x2="9" y1="10" y2="10" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-toast position="bottom-right"></p-toast>
<p-confirmPopup></p-confirmPopup>
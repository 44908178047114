<page>
  <ng-template>
    <div>
      <div class="super-title">PHOTOS</div>
      <p-divider type="solid"></p-divider>
      <ng-container>
        <div class="panel panel-default">
          <div class="panel-body">
            <ng-container *ngIf="showGrid">

              <div class="card-container">
                <ng-container [ngSwitch]="currentDeviceState">

                  <ng-container *ngSwitchCase="'desktop-landscape'">
                    <ng-container *ngTemplateOutlet="desktopLandscape"></ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'mobile-portrait'">
                    <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                  </ng-container>
                  <ng-container *ngSwitchCase="'tablet-portrait'">
                    <ng-container *ngTemplateOutlet="mobilePortrait"></ng-container>
                  </ng-container>

                </ng-container>
              </div>


              <br />




              <div class="card-container">
                <!-- this is for album section 1 image -->
                <p-card class="card">
                  <ng-template pTemplate="header">
                    <div class="profile-heading-title">
                      ALBUM PICTURE 1
                    </div>
                  </ng-template>
                  <ng-template pTemplate="body">
                    <ng-containter *ngIf="!ui?.ShowAlbumImage1Tmb">
                      <div class="upload-image">
                        <img [ngClass]="{
                              'upload-image-icon': !ui?.ShowAlbumImage1TmbCa,
                              'img-thumbnail-profile-image': ui?.ShowAlbumImage1Tmb
                            }" (click)="fileinputAlbumPhoto1.click()"
                          src="./../../../../../assets/image/uploadimage.webp" />
                      </div>
                    </ng-containter>
                    <ng-containter *ngIf="ui?.ShowAlbumImage1Tmb">
                      <div class="image-shell">
                        <img [src]="ui?.CandidateAlbumImage1TmbCa" class="elegant-frame" (click)="
                          makeitbig(
                            ui.CandidateAlbumImage1Tmb
                          )
                        " />

                      </div>

                    </ng-containter>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <div class="flex justify-content-end">
                      <div>
                        <div *ngIf="!ui?.CandidateAlbumImage1TmbUserLocked"
                          (click)="userUnLockOrUnlockImage(ui,'album1')">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-lock-open">
                            <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
                            <path d="M7 11V7a5 5 0 0 1 9.9-1" />
                          </svg>
                        </div>
                        <div *ngIf="ui?.CandidateAlbumImage1TmbUserLocked"
                          (click)="userUnLockOrUnlockImage(ui,'album1')">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-lock-keyhole">
                            <circle cx="12" cy="16" r="1" />
                            <rect x="3" y="10" width="18" height="12" rx="2" />
                            <path d="M7 10V7a5 5 0 0 1 10 0v3" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <div (click)="fileinputAlbumPhoto1.click()" class="add-cursor pl-5 pr-5">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-user-round-pen">
                            <path d="M2 21a8 8 0 0 1 10.821-7.487" />
                            <path
                              d="M21.378 16.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" />
                            <circle cx="10" cy="8" r="5" />
                          </svg>
                        </div>

                      </div>
                      <div>
                        <div (click)="deleteAlbum1Image()" class="add-cursor">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-trash-2">
                            <path d="M3 6h18" />
                            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                            <line x1="10" x2="10" y1="11" y2="17" />
                            <line x1="14" x2="14" y1="11" y2="17" />
                          </svg>
                        </div>

                      </div>
                    </div>
                  </ng-template>
                </p-card>
                <!-- this is for album section 1 image -->


                <!-- this is for album section 2 image -->
                <p-card class="card">
                  <ng-template pTemplate="header">
                    <div class="profile-heading-title">
                      ALBUM PICTURE 2
                    </div>
                  </ng-template>

                  <ng-template pTemplate="body">
                    <div>
                      <ng-containter *ngIf="!ui?.ShowAlbumImage2Tmb">

                        <div class="upload-image">
                          <img [ngClass]="{
                              'upload-image-icon': !ui?.ShowAlbumImage2TmbCa,
                              'img-thumbnail-profile-image': ui?.ShowAlbumImage2Tmb
                            }" (click)="fileinputAlbumPhoto2.click()" src="./../../../../../assets/image/upload.png" />
                        </div>

                      </ng-containter>
                      <ng-containter *ngIf="ui?.ShowAlbumImage2Tmb">

                        <div class="image-shell">
                          <img [src]="ui?.CandidateAlbumImage2TmbCa" class="elegant-frame" (click)="
                        makeitbig(
                          ui.CandidateAlbumImage2Tmb
                        )
                      " />

                        </div>


                      </ng-containter>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <div class="flex justify-content-end">
                      <div>
                        <div *ngIf="!ui?.CandidateAlbumImage2TmbUserLocked"
                          (click)="userUnLockOrUnlockImage(ui,'album2')">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-lock-open">
                            <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
                            <path d="M7 11V7a5 5 0 0 1 9.9-1" />
                          </svg>
                        </div>
                        <div *ngIf="ui?.CandidateAlbumImage2TmbUserLocked"
                          (click)="userUnLockOrUnlockImage(ui,'album2')">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-lock-keyhole">
                            <circle cx="12" cy="16" r="1" />
                            <rect x="3" y="10" width="18" height="12" rx="2" />
                            <path d="M7 10V7a5 5 0 0 1 10 0v3" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <div (click)="fileinputAlbumPhoto2.click()" class="add-cursor pl-5 pr-5">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-user-round-pen">
                            <path d="M2 21a8 8 0 0 1 10.821-7.487" />
                            <path
                              d="M21.378 16.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" />
                            <circle cx="10" cy="8" r="5" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <div (click)="deleteAlbum2Image()" class="add-cursor">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-trash-2">
                            <path d="M3 6h18" />
                            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                            <line x1="10" x2="10" y1="11" y2="17" />
                            <line x1="14" x2="14" y1="11" y2="17" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                </p-card>
                <!-- this is for album section 2 image -->



                <!-- this is for album section 3 image -->
                <p-card class="card">
                  <ng-template pTemplate="header">
                    <div class="profile-heading-title">
                      ALBUM PICTURE 3
                    </div>
                  </ng-template>
                  <ng-template pTemplate="body">
                    <div>
                      <ng-containter *ngIf="!ui?.ShowAlbumImage3Tmb">

                        <div class="upload-image">
                          <img [ngClass]="{
                              'upload-image-icon': !ui?.ShowAlbumImage3TmbCa,
                              'img-thumbnail-profile-image': ui?.ShowAlbumImage3Tmb
                            }" (click)="fileinputAlbumPhoto3.click()" src="./../../../../../assets/image/upload.png" />
                        </div>

                      </ng-containter>
                      <ng-containter *ngIf="ui?.ShowAlbumImage3Tmb">

                        <div class="image-shell">
                          <img [src]="ui?.CandidateAlbumImage3TmbCa" class="elegant-frame" (click)="
                      makeitbig(
                        ui.CandidateAlbumImage3Tmb
                      )
                    " />

                        </div>




                      </ng-containter>
                    </div>
                  </ng-template>

                  <ng-template pTemplate="footer">
                    <div class="flex justify-content-end">
                      <div>
                        <div *ngIf="!ui?.CandidateAlbumImage3TmbUserLocked"
                          (click)="userUnLockOrUnlockImage(ui,'album3')">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-lock-open">
                            <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
                            <path d="M7 11V7a5 5 0 0 1 9.9-1" />
                          </svg>
                        </div>
                        <div *ngIf="ui?.CandidateAlbumImage3TmbUserLocked"
                          (click)="userUnLockOrUnlockImage(ui,'album3')">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-lock-keyhole">
                            <circle cx="12" cy="16" r="1" />
                            <rect x="3" y="10" width="18" height="12" rx="2" />
                            <path d="M7 10V7a5 5 0 0 1 10 0v3" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <div (click)="fileinputAlbumPhoto3.click()" class="add-cursor pl-5 pr-5">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-user-round-pen">
                            <path d="M2 21a8 8 0 0 1 10.821-7.487" />
                            <path
                              d="M21.378 16.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" />
                            <circle cx="10" cy="8" r="5" />
                          </svg>
                        </div>
                      </div>
                      <div>
                        <div (click)="deleteAlbum3Image()" class="add-cursor">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-trash-2">
                            <path d="M3 6h18" />
                            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                            <line x1="10" x2="10" y1="11" y2="17" />
                            <line x1="14" x2="14" y1="11" y2="17" />
                          </svg>
                        </div>
                      </div>
                    </div>


                  </ng-template>

                </p-card>
                <!-- this is for album section 3 image -->



                <!-- this is for album section 4 image -->

                <p-card class="card">
                  <ng-template pTemplate="header">
                    <div class="profile-heading-title">
                      ALBUM PICTURE 4
                    </div>
                  </ng-template>
                  <ng-template pTemplate="body">

                    <div>
                      <ng-containter *ngIf="!ui?.ShowAlbumImage4Tmb">
                        <div class="upload-image">
                          <img [ngClass]="{
                              'upload-image-icon': !ui?.ShowAlbumImage4Tmb,
                              'img-thumbnail-profile-image': ui?.ShowAlbumImage4Tmb
                            }" (click)="fileinputAlbumPhoto4.click()" src="./../../../../../assets/image/upload.png" />
                        </div>
                      </ng-containter>
                      <ng-containter *ngIf="ui?.ShowAlbumImage4Tmb">

                        <div class="image-shell">
                          <img [src]="ui?.CandidateAlbumImage4TmbCa" class="elegant-frame"
                            onError="this.src='../../../../assets/image/404Image.jpeg';" (click)="
                  makeitbig(
                    ui.CandidateAlbumImage4Tmb
                  )
                " />

                        </div>


                      </ng-containter>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <div class="flex justify-content-end">
                      <div>

                        <div *ngIf="!ui?.CandidateAlbumImage4TmbUserLocked"
                          (click)="userUnLockOrUnlockImage(ui,'album4')">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-lock-open">
                            <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
                            <path d="M7 11V7a5 5 0 0 1 9.9-1" />
                          </svg>
                        </div>
                        <div *ngIf="ui?.CandidateAlbumImage4TmbUserLocked"
                          (click)="userUnLockOrUnlockImage(ui,'album4')">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-lock-keyhole">
                            <circle cx="12" cy="16" r="1" />
                            <rect x="3" y="10" width="18" height="12" rx="2" />
                            <path d="M7 10V7a5 5 0 0 1 10 0v3" />
                          </svg>
                        </div>

                      </div>
                      <div>

                        <div (click)="fileinputAlbumPhoto4.click()" class="add-cursor pl-5 pr-5">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-user-round-pen">
                            <path d="M2 21a8 8 0 0 1 10.821-7.487" />
                            <path
                              d="M21.378 16.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" />
                            <circle cx="10" cy="8" r="5" />
                          </svg>
                        </div>

                      </div>
                      <div>
                        <div (click)="deleteAlbum4Image()" class="add-cursor">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-trash-2">
                            <path d="M3 6h18" />
                            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                            <line x1="10" x2="10" y1="11" y2="17" />
                            <line x1="14" x2="14" y1="11" y2="17" />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </p-card>
                <!-- this is for album section 4 image -->




              </div>





              <br />

              <div class="card-container">

                <!-- this is for FAMILY section 4 image -->
                <p-card class="card">
                  <ng-template pTemplate="header">
                    <div class="profile-heading-title">
                      FAMILY PICTURE 1
                    </div>
                  </ng-template>
                  <ng-template pTemplate="body">
                    <div>
                      <ng-containter *ngIf="!ui?.ShowFamilyImage1Tmb">

                        <div class="upload-image">
                          <img [ngClass]="{
                              'upload-image-icon': !ui?.ShowFamilyImage1Tmb,
                              'img-thumbnail-profile-image':
                                ui?.ShowFamilyImage1Tmb
                            }" (click)="fileinputFamilyPhoto1.click()"
                            src="./../../../../../assets/image/upload.png" />
                        </div>

                      </ng-containter>
                      <ng-containter *ngIf="ui?.ShowFamilyImage1Tmb">


                        <div class="image-shell">
                          <img [src]="ui?.CandidateFamilyImage1TmbCa" class="elegant-frame" (click)="
                    makeitbig(
                      ui.CandidateFamilyImage1Tmb
                    )
                  " />


                        </div>




                      </ng-containter>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <div class="flex justify-content-end">
                      <div>

                        <div *ngIf="!ui?.CandidateFamilyImage1TmbUserLocked"
                          (click)="userUnLockOrUnlockImage(ui,'family1')">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-lock-open">
                            <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
                            <path d="M7 11V7a5 5 0 0 1 9.9-1" />
                          </svg>
                        </div>
                        <div *ngIf="ui?.CandidateFamilyImage1TmbUserLocked"
                          (click)="userUnLockOrUnlockImage(ui,'family1')">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-lock-keyhole">
                            <circle cx="12" cy="16" r="1" />
                            <rect x="3" y="10" width="18" height="12" rx="2" />
                            <path d="M7 10V7a5 5 0 0 1 10 0v3" />
                          </svg>
                        </div>


                      </div>
                      <div>
                        <div (click)="fileinputFamilyPhoto1.click()" class="add-cursor pl-5 pr-5">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-user-round-pen">
                            <path d="M2 21a8 8 0 0 1 10.821-7.487" />
                            <path
                              d="M21.378 16.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" />
                            <circle cx="10" cy="8" r="5" />
                          </svg>
                        </div>
                      </div>
                      <div>

                        <div (click)="deleteFamily1Image()" class="add-cursor">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-trash-2">
                            <path d="M3 6h18" />
                            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                            <line x1="10" x2="10" y1="11" y2="17" />
                            <line x1="14" x2="14" y1="11" y2="17" />
                          </svg>
                        </div>

                      </div>
                    </div>
                  </ng-template>

                </p-card>
                <!-- this is for FAMILY section 1 image -->



                <!-- this is for FAMILY section 2 image -->

                <p-card class="card">
                  <ng-template pTemplate="header">
                    <div class="profile-heading-title">
                      FAMILY PICTURE 2
                    </div>
                  </ng-template>
                  <ng-template pTemplate="body">

                    <div>
                      <ng-containter *ngIf="!ui?.ShowFamilyImage2Tmb">
                        <div class="upload-image">
                          <img [ngClass]="{
                              'upload-image-icon': !ui?.ShowFamilyImage2Tmb,
                              'img-thumbnail-profile-image':
                                ui?.ShowFamilyImage2Tmb
                            }" (click)="fileinputFamilyPhoto2.click()"
                            src="./../../../../../assets/image/upload.png" />
                        </div>
                      </ng-containter>
                      <ng-containter *ngIf="ui?.ShowFamilyImage2Tmb">
                        <div class="image-shell">
                          <img [src]="ui?.CandidateFamilyImage2TmbCa" class="elegant-frame"
                            onError="this.src='../../../../assets/image/404Image.jpeg';" (click)="
                          makeitbig(
                            ui.CandidateFamilyImage2Tmb
                          )
                        " />

                        </div>

                      </ng-containter>
                    </div>
                  </ng-template>
                  <ng-template pTemplate="footer">
                    <div class="flex justify-content-end">

                      <div *ngIf="!ui?.CandidateFamilyImage2TmbUserLocked"
                        (click)="userUnLockOrUnlockImage(ui,'family2')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                          class="lucide lucide-lock-open">
                          <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
                          <path d="M7 11V7a5 5 0 0 1 9.9-1" />
                        </svg>
                      </div>
                      <div *ngIf="ui?.CandidateFamilyImage2TmbUserLocked"
                        (click)="userUnLockOrUnlockImage(ui,'family2')">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                          stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                          class="lucide lucide-lock-keyhole">
                          <circle cx="12" cy="16" r="1" />
                          <rect x="3" y="10" width="18" height="12" rx="2" />
                          <path d="M7 10V7a5 5 0 0 1 10 0v3" />
                        </svg>
                      </div>
                      <div>

                        <div (click)="fileinputFamilyPhoto2.click()" class="add-cursor pl-5 pr-5">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-user-round-pen">
                            <path d="M2 21a8 8 0 0 1 10.821-7.487" />
                            <path
                              d="M21.378 16.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" />
                            <circle cx="10" cy="8" r="5" />
                          </svg>
                        </div>

                      </div>
                      <div>

                        <div (click)="deleteFamily2Image()" class="add-cursor">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                            class="lucide lucide-trash-2">
                            <path d="M3 6h18" />
                            <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                            <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                            <line x1="10" x2="10" y1="11" y2="17" />
                            <line x1="14" x2="14" y1="11" y2="17" />
                          </svg>
                        </div>

                      </div>
                    </div>
                  </ng-template>
                </p-card>

                <!-- this is for FAMILY section 2 image -->




              </div>

            </ng-container>
          </div>

          <div class="flex">
            <div class="col-12 sm:col-6 md:col-4 lg:col-10"></div>
            <div class="col-12 sm:col-6 md:col-4 lg:col-2 text-right">
              <button pButton (click)="saveInformation()">SAVE</button>
            </div>
          </div>
          <br />


        </div>

        <div class="image-upload">
          <input #fileinputProfileImage id="fileinputProfileImage" accept="image/jpeg, image/png, image/webp"
            (change)="onChangeProfileImage($event)" type="file" />
          <input #fileinputAlbumPhoto1 id="fileinputAlbumPhoto1" accept="image/jpeg, image/png, image/webp"
            (change)="onChangeAlbumImage1($event)" type="file" />
          <input #fileinputAlbumPhoto2 id="fileinputAlbumPhoto2" accept="image/jpeg, image/png, image/webp"
            (change)="onChangeAlbumImage2($event)" type="file" />
          <input #fileinputAlbumPhoto3 id="fileinputAlbumPhoto3" accept="image/jpeg, image/png, image/webp"
            (change)="onChangeAlbumImage3($event)" type="file" />
          <input #fileinputAlbumPhoto4 id="fileinputAlbumPhoto4" accept="image/jpeg, image/png, image/webp"
            (change)="onChangeAlbumImage4($event)" type="file" />
          <input #fileinputFamilyPhoto1 id="fileinputFamilyPhoto1" accept="image/jpeg, image/png, image/webp"
            (change)="onChangeFamilyImage1($event)" type="file" />
          <input #fileinputFamilyPhoto2 id="fileinputFamilyPhoto2" accept="image/jpeg, image/png, image/webp"
            (change)="onChangeFamilyImage2($event)" type="file" />
        </div>
      </ng-container>

    </div>

    <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [closable]="true">
      <p-galleria [(value)]="imageUrls" [thumbnailsPosition]="position" [responsiveOptions]="responsiveOptions"
        [containerStyle]="{ 'max-width': '640px' }" [numVisible]="5" [activeIndex]="activeIndex">
        <ng-template pTemplate="item" let-item>
          <img [src]="item" class="image-shell-2" />
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
          <div class="grid grid-nogutter justify-content-center">
            <img [src]="item" class="image-thumbnail" />
          </div>
        </ng-template>
      </p-galleria>
    </p-dialog>


    <super-floating-loader [isLoading]="showLoader"></super-floating-loader>


    <ng-template #mobilePortrait>
      <div>
        <ng-container *ngIf="!ui?.ShowProfileImageTmb">
          <p-card header="">
            <div class="upload-image">
              <img [ngClass]="{
                  'upload-image-icon': !ui?.ShowProfileImageTmb,
                  'img-thumbnail-profile-image':
                    ui?.ShowProfileImageTmb
                }" (click)="fileinputProfileImage.click()" src="./../../../../../assets/image/upload.png" />
            </div>
          </p-card>
        </ng-container>
        <ng-container *ngIf="ui?.ShowProfileImageTmb">
          <p-card header="">
            <p-card-content style="display: flex; justify-content: center">
              <div class="image-shell">
                <img [src]="ui?.CandidateProfileImageTmbCa" class="profile-image-mobile" (click)="
                  makeitbig(
                    ui.CandidateProfileImageTmb
                  )
                " />
              </div>
              <i [ngClass]="image?.CandidateProfileImageTmbAdminLocked ? 'pi pi-lock user-icon-lock' : 'pi pi-lock-open icon-lock-open icon-un-locked'"
                (click)="userUnLockOrUnlockImage(image)">
              </i>
            </p-card-content>
            <ng-template pTemplate="footer">
              <div class="flex justify-content-end">
                <div>
                  <img src="../../../../assets/image/editimage.png" alt="" class="image-edit"
                    (click)="fileinputProfileImage.click()" />
                </div>
                <div>
                  <img src="../../../../assets/image/trashcang.png" alt="" class="image-delete"
                    (click)="deleteProfileImage()" />
                </div>
              </div>
            </ng-template>
          </p-card>
        </ng-container>
      </div>
    </ng-template>

    <ng-template #desktopLandscape>
      <p-card class="card">
        <ng-template pTemplate="header">
          <div class="profile-heading-title">
            PROFILE IMAGE
          </div>
        </ng-template>
        <ng-template pTemplate="body">

          <ng-container *ngIf="!ui?.ShowProfileImageTmb">

            <div class="upload-image">
              <img [ngClass]="{
                  'upload-image-icon': !ui?.ShowProfileImageTmb,
                  'img-thumbnail-profile-image':
                    ui?.ShowProfileImageTmb
                }" (click)="fileinputProfileImage.click()" src="./../../../../../assets/image/upload.png" />
            </div>


          </ng-container>
          <ng-container *ngIf="ui?.ShowProfileImageTmb">
            <div class="image-shell">
              <img [src]="ui?.CandidateProfileImageTmbCa" class="elegant-frame" (click)="
                  makeitbig(
                    ui.CandidateProfileImageTmb
                  )
                " />

            </div>
          </ng-container>
        </ng-template>
        <ng-template pTemplate="footer">
          <div class="flex justify-content-end icon-vault">
            <div>
              <div *ngIf="!ui?.CandidateProfileImageTmbUserLocked" (click)="userUnLockOrUnlockImage(ui,'profile')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-lock-open">
                  <rect width="18" height="11" x="3" y="11" rx="2" ry="2" />
                  <path d="M7 11V7a5 5 0 0 1 9.9-1" />
                </svg>
              </div>

              <div *ngIf="ui?.CandidateProfileImageTmbUserLocked" (click)="userUnLockOrUnlockImage(ui,'profile')">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-lock-keyhole">
                  <circle cx="12" cy="16" r="1" />
                  <rect x="3" y="10" width="18" height="12" rx="2" />
                  <path d="M7 10V7a5 5 0 0 1 10 0v3" />
                </svg>
              </div>

            </div>
            <div>
              <div (click)="fileinputProfileImage.click()" class="add-cursor pl-5 pr-5">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-user-round-pen">
                  <path d="M2 21a8 8 0 0 1 10.821-7.487" />
                  <path
                    d="M21.378 16.626a1 1 0 0 0-3.004-3.004l-4.01 4.012a2 2 0 0 0-.506.854l-.837 2.87a.5.5 0 0 0 .62.62l2.87-.837a2 2 0 0 0 .854-.506z" />
                  <circle cx="10" cy="8" r="5" />
                </svg>
              </div>

            </div>
            <div>
              <div (click)="deleteProfileImage()" class="add-cursor">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                  stroke="#b9c5d0" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
                  class="lucide lucide-trash-2">
                  <path d="M3 6h18" />
                  <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
                  <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
                  <line x1="10" x2="10" y1="11" y2="17" />
                  <line x1="14" x2="14" y1="11" y2="17" />
                </svg>
              </div>
            </div>
          </div>
        </ng-template>
      </p-card>
    </ng-template>
  </ng-template>



</page>

<p-toast position="bottom-right"></p-toast>
<super-floating-loader [isLoading]="showLoader" [backgroundColor]="'rgba(0, 0, 0, 0.07)'"></super-floating-loader>
<ng-containter *ngIf="!showLoader">
  <div class="grid align-items-center style-action">

    <div class="col-12 md:col-2 text-left">
      <img [src]="userInformation?.userImageReal" alt="..loading" class="profile-image-no-cursor img-thumbnail" />
    </div>


    <div class="col-12 md:col-1 hidden sm:block">
      <p-divider layout="vertical"></p-divider>
    </div>

    <div class="profile-info">
      <div class="flex align-items-center mb-3">
        <span class="pr-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
            stroke="#0081cf" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
            class="lucide lucide-contact">
            <path d="M16 2v2" />
            <path d="M7 22v-2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v2" />
            <path d="M8 2v2" />
            <circle cx="12" cy="11" r="3" />
            <rect x="3" y="4" width="18" height="18" rx="2" />
          </svg>
        </span>
        <span class="profile-label">Name</span>
        <span class="col-12 sm:col-9 py-2 profile-value" style="color: #0081cf !important; font-weight: 700;">{{
          userInformation?.name
          }}</span>
      </div>

      <div class="flex align-items-center mb-3">
        <span class="pr-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
            stroke="#E11D48" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
            class="lucide lucide-circle-user-round">
            <path d="M18 20a6 6 0 0 0-12 0" />
            <circle cx="12" cy="10" r="4" />
            <circle cx="12" cy="12" r="10" />
          </svg>
        </span>
        <span class="profile-label">Profile</span>
        <span class="col-12 sm:col-9 py-2 profile-value" style="color: #E11D48 !important; font-weight: 700;">{{
          userInformation?.username }}</span>
      </div>

      <div class="flex align-items-center mb-3">
        <span class="pr-3">

          @if(userInformation?.Gender!=undefined && userInformation?.Gender==='Female')
          {
          <svg width="20px" height="20px" viewBox="4 3 15 16" xmlns="http://www.w3.org/2000/svg" stroke="#9333EA"
            stroke-width="1" fill="none">
            <circle cx="9" cy="9" r="3.75"></circle>
            <line x1="9" y1="12.75" x2="9" y2="21"></line>
            <line x1="4.5" y1="16.5" x2="13.5" y2="16.5"></line>
          </svg>

          }
          @else if(userInformation?.Gender===undefined || userInformation?.Gender==='Male')
          {
          <svg width="20px" height="20px" viewBox="4 3 15 16" xmlns="http://www.w3.org/2000/svg" stroke="#9333EA"
            stroke-width="1" fill="none">
            <line x1="14" y1="2" x2="18" y2="6" />
            <line x1="14" y1="6" x2="18" y2="2" />
            <circle cx="16" cy="10" r="2.5" />
            <line x1="16" y1="12.5" x2="16" y2="18" />
          </svg>
          }

        </span>
        <span class="profile-label">Gender</span>
        <span class="col-12 sm:col-9 py-2 profile-value" style="color: #9333EA !important; font-weight: 700;">{{
          userInformation?.Gender }}</span>
      </div>

      <div class="flex align-items-center mb-3">
        <span class="pr-3">


          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
            stroke="#0891B2" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
            class="lucide lucide-calendar-clock">
            <path d="M21 7.5V6a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h3.5" />
            <path d="M16 2v4" />
            <path d="M8 2v4" />
            <path d="M3 10h5" />
            <path d="M17.5 17.5 16 16.3V14" />
            <circle cx="16" cy="16" r="6" />
          </svg>

        </span>
        <span class="profile-label">Registered Date</span>
        <span class="col-12 sm:col-9 py-2 profile-value" style="color: #0891B2 !important; font-weight: 700;">{{
          userInformation?.registeredDate | date }}</span>
      </div>

      <div class="flex align-items-center mb-3">
        <span class="pr-3">

          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
            stroke="#26b7ff" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-gem">
            <path d="M6 3h12l4 6-10 13L2 9Z" />
            <path d="M11 3 8 9l4 13 4-13-3-6" />
            <path d="M2 9h20" />
          </svg>


        </span>
        <span class="profile-label">Membership Type</span>
        <div class="membership-type profile-value text-primary pl-2">{{userInformation?.membershipType}}</div>
      </div>

      <div class="flex align-items-center mb-3">
        <span class="pr-3">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
            stroke="#0D9488" stroke-width="1" stroke-linecap="round" stroke-linejoin="round"
            class="lucide lucide-clock-5">
            <circle cx="12" cy="12" r="10" />
            <polyline points="12 6 12 12 14.5 16" />
          </svg>
        </span>
        <span class="profile-label">Renewal Date</span>
        <div class="col-12 sm:col-9 py-2 profile-value" style="color: #0D9488 !important; font-weight: 700;">{{
          userInformation?.renewalDate | date }}</div>
      </div>
    </div>
  </div>

  <p-dialog header="IMAGE GALLERIA" [(visible)]="showLargeImage" [style]="{ width: '75vw' }" [closable]="true">
    <large-image-window [tc]="imageToDisplay"></large-image-window>
  </p-dialog>

  <p-toast position="bottom-right"></p-toast>
  <p-confirmPopup></p-confirmPopup>
</ng-containter>
<super-floating-loader [isLoading]="showLoader" [backgroundColor]="'rgba(0, 0, 0, 0.07)'"></super-floating-loader>
<ng-containter *ngIf="!showLoader" >
  <div class="my-horizontal-list">
    <div>
      <div class="table-responsive">
        <table class="table" *ngIf="savedSearches?.length > 0">
          <tr>
            <td>
              <div class="my-horizontal-list saved-searches-container">
                <div
                  *ngFor="let item of savedSearches | paginate: { itemsPerPage: 10, currentPage: savedSearchPageNumber, id: 'SavedSearches' }"
                  style="margin-right: 5px">
                  <div class="p-inputgroup search-tag" (click)="navigateToSearch($event,item)">
                    <span class="p-inputgroup-addon">
                      <i class="pi pi-search"></i>
                    </span>
                    <span class="p-inputtext p-component search-label">
                      {{ item.searchName }}
                    </span>
                    <button pButton 
                            type="button" 
                            icon="pi pi-times" 
                            class="p-button-text p-button-rounded p-button-sm"
                            (click)="removeSearch($event,item)">
                    </button>
                  </div>
                </div>      
              </div>
            </td>
          </tr>
          <tr>
            <td>

            </td>
          </tr>
        </table>
      </div>

    </div>
  </div>

  <div style="margin-top: 10px; text-align: center" *ngIf="savedSearches?.length>0">
    <pagination-controls (pageChange)="savedSearchPageNumber = $event" id="SavedSearches">
    </pagination-controls>
  </div>

  <div *ngIf="savedSearches?.length === 0" class="no-value-text-saved">
    No saved searches yet
  </div>
</ng-containter>

<p-confirmPopup></p-confirmPopup>
<p-toast position="bottom-right"></p-toast>

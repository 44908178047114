import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { LocalStore } from 'src/app/common/reusable/globalservice/internalflyweight.service';
import { Commonservice } from 'src/app/common/reusable/servicecenter/commonservice.component';
import { DynamicComponentService } from 'src/app/common/reusable/servicecenter/DynamicComponentService';
import { SignalRService } from 'src/app/common/reusable/servicecenter/SignalRService';
import { SignalStateService } from 'src/app/common/reusable/servicecenter/StateService';
import { ActionResponseModel } from 'src/app/dto/ActionResponseModel';
import { LogOutActivityModel } from "src/app/dto/LogOutActivityModel";
import { SendMessageToAdminView } from 'src/app/dto/sendMaessageToAdminView';

@Component({
  selector: 'intermediate',
  templateUrl: './intermediate.component.html',
  styleUrl: './intermediate.component.css',
  providers:[MessageService]
})
export class IntermediateComponent {
  text: string = ''; // For the PrimeNG Editor
  showContactForm: boolean = false;

  constructor(
    private signalStateService: SignalStateService, private signalRService: SignalRService, private dynamicComponentService: DynamicComponentService, 
    private router: Router,
    private messageService: MessageService,
    private commonService: Commonservice, private localStore: LocalStore
  ) {}

  ngOnInit(): void {
    // Optionally, you can add a check here to ensure the user is actually disabled
    // If not, redirect to main page or login
  }

  // Logout functionality
  onLogout(): void {
    const self = this;
    let LogOutActivityModel = {} as LogOutActivityModel;    
    this.commonService.logOut(LogOutActivityModel).subscribe({
      next: () => {
        setTimeout(() => {
          localStorage.clear();
          sessionStorage.clear();
          self.signalRService.disconnect();
          self.signalStateService.clearAll();
          this.dynamicComponentService.closeAllChatWindows();
          self.router.navigateByUrl('/welcome');
        }, 120); 
      },
      error: (error) => {
        // Handle logout error
        this.messageService.add({
          severity: 'error', 
          summary: 'Logout Failed', 
          detail: 'Unable to logout. Please try again.'
        });
      }
    });
  }

  // Toggle contact form visibility
  toggleContactForm(): void {
    this.showContactForm = !this.showContactForm;
  }

  // Send message to administrator
  sendMessageToAdmin(): void {
    if (!this.text.trim()) {
      this.messageService.add({
        severity: 'warn', 
        summary: 'Warning', 
        detail: 'Please enter a message before sending.'
      });
      return;
    }

    let sendMessageToAdminView: SendMessageToAdminView = {} as SendMessageToAdminView;
    sendMessageToAdminView.FromUser= this.localStore.getCurrentLoggedInUserSync().UserName;
    sendMessageToAdminView.Message = this.text;
    sendMessageToAdminView.SendDate = new Date();    
    this.commonService.sendMessageToAdmin(sendMessageToAdminView).subscribe({
      next: (data:ActionResponseModel) => {
        if(data.ActivityWorked){
          this.messageService.add({
            severity: 'success', 
            summary: 'Message Sent', 
            detail: 'Your message has been sent to the administrator.'
          });
        }       
        this.text = ''; // Clear the editor
        this.showContactForm = false;
      },
      error: (error) => {
        this.messageService.add({
          severity: 'error', 
          summary: 'Send Failed', 
          detail: 'Unable to send message. Please try again.'
        });
      }
    });
  }
}

<h2 class="benefits-title">Subscription Plans</h2>
<div class="grid">
    <div class="col-12 md:col-6 lg:col-3" *ngFor="let plan of plans">
        <p-card [styleClass]="'plan-card ' + (plan.recommended ? 'recommended' : '')">
            <ng-template pTemplate="header">
                <div class="plan-header" [style.background-color]="plan.bgColor">
                    <div *ngIf="plan.recommended" class="recommended-badge">
                        Most Popular
                    </div>
                    <h3 class="text-xl font-medium mb-2">{{ plan.type }}</h3>
                    <p class="text-600 m-0">{{ plan.duration }}</p>
                </div>
            </ng-template>

            <div class="pricing-content">
                <div class="price-section">
                    <span class="currency">₹</span>
                    <span class="amount">{{ plan.price }}</span>
                </div>

                <p-button [label]="'Choose ' + plan.type" styleClass="p-button-outlined w-full"
                    [style]="{'color': plan.iconColor}">
                </p-button>
            </div>
        </p-card>
    </div>
</div>

<!-- Purchase Button -->
<!-- <div class="flex justify-content-center mb-5">
    <p-button label="Purchase new subscription" icon="pi pi-shopping-cart"
        styleClass="p-button-rounded p-button-primary">
    </p-button>
</div> -->
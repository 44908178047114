<page>
  <ng-template>
    <div>
      <div class="super-title">
        HIDE PROFILE
      </div>
      <p-divider type="solid"></p-divider>
      <div class="panel panel-default">
        <div class="panel-body">
          <div class="hide-box">
            <div class="rc1 text-left">SHOW PROFILE</div>
            <div class="rc2">
              <p-checkbox [(ngModel)]="userprofileVisible" [binary]="true" inputId="binary"
                (ngModelChange)="makepersonisibleorhide($event)"></p-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p-toast position="bottom-right"></p-toast>
    <p-confirmPopup></p-confirmPopup>


    <super-floating-loader [isLoading]="showLoader"></super-floating-loader>

  </ng-template>
</page>

<div class="benefits-container">
    <h2 class="benefits-title">Premium Membership Benefits</h2>
    
    <div class="benefits-grid">
      <div class="benefit-card">
        <div class="benefit-icon">
          <i class="pi pi-users"></i>
        </div>
        <div class="benefit-content">
          <div class="benefit-subtitle">Daily Contacts</div>
          <p>Access 24 Contact Details of Members per day</p>
        </div>
      </div>
      
      <div class="benefit-card">
        <div class="benefit-icon">
          <i class="pi pi-comments"></i>
        </div>
        <div class="benefit-content">
          <div class="benefit-subtitle"> Custom Messaging</div>
          <p>Send and receive personalized messages with potential matches</p>
        </div>
      </div>
      
      <div class="benefit-card">
        <div class="benefit-icon">
          <i class="pi pi-bookmark"></i>
        </div>
        <div class="benefit-content">
          <div class="benefit-subtitle">Persistent Features</div>
          <p>Bookmarks continue throughout your membership</p>
        </div>
      </div>

      <div class="benefit-card">
        <div class="benefit-icon">
          <i class="pi pi-bookmark"></i>
        </div>
        <div class="benefit-content">
          <div class="benefit-subtitle">Persistent Features</div>
          <p>Express Interest continue throughout your membership</p>
        </div>
      </div>
      
      <div class="benefit-card">
        <div class="benefit-icon">
          <i class="pi pi-images"></i>
        </div>
        <div class="benefit-content">
          <div class="benefit-subtitle">Privacy Control</div>
          <p>Lock & Unlock images to control visibility in profile image gallery</p>
        </div>
      </div>
      
      <div class="benefit-card">
        <div class="benefit-icon">
          <i class="pi pi-comment"></i>
        </div>
        <div class="benefit-content">
          <div class="benefit-subtitle">Live Chat Access</div>
          <p>Chat directly from member cards & search results</p>
        </div>
      </div>
      
      <div class="benefit-card">
        <div class="benefit-icon">
          <i class="pi pi-image"></i>
        </div>
        <div class="benefit-content">
          <div class="benefit-subtitle">Image Matching</div>
          <p>Find compatible matches based on image preferences</p>
        </div>
      </div>

      <div class="benefit-card">
        <div class="benefit-icon">
          <i class="pi pi-image"></i>
        </div>
        <div class="benefit-content">
          <div class="benefit-subtitle">Recommendations</div>
          <p>We will recomend, what we possibly think is the best match for You.</p>
        </div>
      </div>

      <div class="benefit-card">
        <div class="benefit-icon">
          <i class="pi pi-comment"></i>
        </div>
        <div class="benefit-content">
          <div class="benefit-subtitle">Express Interest Status</div>
          <p>You can know the status of the express interest You have sent</p>
        </div>
      </div>
      <div class="benefit-card">
        <div class="benefit-icon">
          <i class="pi pi-comment"></i>
        </div>
        <div class="benefit-content">
          <div class="benefit-subtitle">Custom Message Status</div>
          <p>You can know the status of the Custom Message You have sent</p>
        </div>
      </div>
      <div class="benefit-card">
        <div class="benefit-icon">
          <i class="pi pi-comment"></i>
        </div>
        <div class="benefit-content">
          <div class="benefit-subtitle">Visitors Statistical Chart</div>
          <p>You will get to see, how many people have visited you</p>
        </div>
      </div>
      <div class="benefit-card">
        <div class="benefit-icon">
          <i class="pi pi-comment"></i>
        </div>
        <div class="benefit-content">
          <div class="benefit-subtitle">Visitors Contact view Statistical Chart</div>
          <p>You will get to see, how many people have visited Your contact</p>
        </div>
      </div>
    </div>
  </div>